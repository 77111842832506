import {
	Box,
	Flex,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spacer,
	Text,
	SkeletonText,
	useToast,
	useDisclosure,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Tag,
	Portal,
	Button,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	Divider,
	DrawerCloseButton,
	DrawerBody,
	VStack,
	FormControl,
	DrawerFooter,
	FormLabel,
	HStack,
	Tooltip,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Switch,
	Center,
} from '@chakra-ui/react'
import { debounce, includes } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Scrollbar from 'react-custom-scrollbars-2'
import ReactExport from 'react-data-export'
import { AiFillDatabase } from 'react-icons/ai'
import { BiArrowBack, BiDownload } from 'react-icons/bi'
import { MdRefresh, MdSearch, MdPrint, MdSort } from 'react-icons/md'
import { BeatLoader, PropagateLoader } from 'react-spinners'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useSharedContext } from '../../../../context/shared.context'
import { useAuthContext } from '../../../../context/auth.context'
import { useLanguageContext } from '../../../../context/locale.context'
import {
	useLoadWidgetDataHeaders,
	useLoadWidgetDrillDownData,
	useLoadWidgetExportData,
	useCheckDottedLineManagerAccess,
	useLoadAssetReportees,
	useSMEDrillDownData,
	useAdditionalFieldUpdate,
} from '../../../../hooks/dashboard.hooks'
import { sharedConst, smartFormConst } from '../../../../utils/action.constant'
import {
	getInlineFields,
	getValueInMillions,
	parseDataForExcelExport,
	getInlineLeaveFields,
	getInlineReferenceFields,
	getInlineRagStatus,
	//changeWidgetDataOnQueryFilters,
} from '../../../../utils/dashboard.utils'
import {
	useLoadActivityListV1,
	useSubmitForm,
	useLoadActivityTypeList,
	useLoadActivityStatusList,
	useLoadActivityDetails,
	useLoadActivityParticipant,
	useEditFeilds,
	useLoadAccessSelectActivity,
} from '../../../../hooks/activity.hooks'
import {
	useLoadAssetRef,
	useLoadFormDynamicFieldList,
	useLoadWorkflowRef,
} from '../../../../hooks/form.hooks'
import { getCustomFormatttedDate } from '../../../../utils/date.utils'
import { WIDGET_WORKSPACE_MODE } from '../../../../utils/widget-constants'
import { useDrilldownContext } from './../../../../context/drilldown.context'
import {
	drilldownConst,
	dashboardConst,
} from './../../../../utils/action.constant'
import DrillDownTable, {
	TH,
	TD,
	TR,
} from '../../../../components/dashboard-table'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'
import Select from '../../../../components/chakra-react-select'
import {
	getMessageUniqueId,
	hasImageOrVideoUrl,
	toSnakeCase,
	validURL,
} from '../../../../utils/common.util'
import {
	formatDDMMYYYYHHMM,
	getCurrentDateUTC,
	getLeaveTimeDate,
} from '../../../../utils/date.utils'
import { QRCodeSVG } from 'qrcode.react'

import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { useHistory } from 'react-router-dom'
import { downloadAwsFile } from '../../../../utils/aws.util'
import DataManagement from '../../../workspace-panel/data-management'
import { AiOutlineBarChart } from 'react-icons/ai'
import { FormInputField } from '../../../../components/form/fieldItem/field.config'
import { prepareFinalFieldValueForEdit } from '../../../../utils/form.util'
import { replaceURL } from '../../../../utils/linkInText.utils'
import { useSmartFormContext } from '../../../../context/smart-form.context'
import { useFieldEditSave } from '../../../../hooks/form.hooks'
import { WORKSPACE_MODE } from '../../../workspace-panel/workspace-panel.const'
import { SmartFormContextProvider } from '../../../../context/smart-form.context'
import FormEditPanel from '../../../form-edit-panel'
import SmartFormPanel from '../../../form-panel/smart-form-panel'
import { FiBarChart2 } from 'react-icons/fi'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import CustomMenu from '../../../../components/menu'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

var objectHash = require('object-hash')

const customStyles = {
	menu: () => ({
		position: 'relative',
	}),
	option: provided => ({
		...provided,
		//   whiteSpace: "nowrap"
	}),
}

function WidgetData({
	onWidgetViewChange,
	clearWidgetData,
	onToggle,
	setIsClickArrow, //simha
	isClickArrow,
}) {
	const toast = useToast()
	const {
		state: { widgetData, queryFilters },
		dispatch: dashboardDispatch,
	} = useDashboardContext()

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		isOpen: isFormEditView,
		onOpen: onOpenFormEditView,
		onClose: onCloseFormEdit,
	} = useDisclosure()
	const {
		isOpen: isOpenNoAccess,
		onOpen: onOpenNoAccess,
		onClose: onCloseNoAccess,
	} = useDisclosure()
	const { mutate: mutateLoadReportees } = useLoadAssetReportees()
	const { mutate } = useLoadActivityParticipant()
	const { mutate: loadFeilds } = useEditFeilds()
	const { mutate: saveEditedFields } = useFieldEditSave()

	const userData = authData
	const history = useHistory()
	const cancelRef = React.useRef()
	const {
		dispatch: dispatchDrilldown,
		state: { drilldownFilterStatus },
	} = useDrilldownContext()
	const {
		state: { fields },
		dispatch: dispatchFormFields,
	} = useSmartFormContext()
	const {
		dispatch,
		state: {
			activity,
			activityTypeDetails,
			isReportingManagerOwner,
			isReportingManagerLead,
			openTeamCollab,
			activityParticipantId,
			isShowLoader,
			showWhatsNext,
			asset_notification_muted,
			isRefreshTimeline,
			random,
		},
	} = useSharedContext()
	const {
		mutate: loadWidgetHeaders,
		data: widgetHeaderList,
		isLoading: isWidgetHeaderLoading,
	} = useLoadWidgetDataHeaders()

	const { mutate: mutateCheckDottedLineManagerAccess } =
		useCheckDottedLineManagerAccess()

	const {
		mutate: loadWidgetDrillDownData,
		isLoading: isWidgetDataLoading,
		data: widgetDrilldownData,
	} = useLoadWidgetDrillDownData()

	const { mutate: loadSMEDrillDownData } = useSMEDrillDownData()

	const {
		mutate: loadWidgetExportData,
		data: widgetExportList,
		isLoading: isExportDataLoading,
	} = useLoadWidgetExportData()

	const { mutate: loadActivity } = useLoadActivityListV1()
	const { mutateAsync: loadWorkflowAsync } = useLoadWorkflowRef()

	const { mutateAsync: mutateAssetAsync } = useLoadAssetRef()

	const { mutate: dynamicFields, data: dynamicFieldsList } =
		useLoadFormDynamicFieldList()
	const { mutate: mutateSubmitForm } = useSubmitForm()
	const { mutate: mutateAdditionalFieldUpdate } = useAdditionalFieldUpdate()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const { mutate: mutateActivity } = useLoadActivityDetails()
	const { mutate: loadAccessSelectActivity } = useLoadAccessSelectActivity()

	const [search, setSearch] = useState('')
	const [isExceedOpen, setIsExceedOpen] = useState(false)
	const [widgetRecords, setWidgetRecords] = useState([])
	const [sortType, setSortType] = useState(null)
	const [sortField, setSortField] = useState(null)
	const [sortFieldName, setSortFieldName] = useState(null)
	const [selectedCheckbox, setSelectedCheckbox] = useState({})
	const [hasMoreData, setHasMoreData] = useState(true)
	const [widgetDataHash, setWidgetDataHash] = useState({})
	const [selectedId, setSelectedId] = useState(0)
	const [scrollAtBottom, setScrollAtBottom] = useState(false)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [leaveData, setLeaveData] = useState([])
	const [freezeAct, setFreezeAct] = useState([])
	const [editItem, setEditItem] = useState({})
	const [modelType, setModelType] = useState('')
	const [openFormView, setopenFormView] = useState(null)
	const [isBulkEdit, setisBulkEdit] = useState(false)
	const [refillView, setRefillView] = useState(false)
	const [participants, setparticipants] = useState([])
	const [loadSuperAdmins, setloadSuperAdmins] = useState([])
	const [headerFieldType, setHeaderFieldType] = useState([])
	const [isFieldValidate, setIsFieldValidate] = useState(false)
	const [error, setError] = useState(null)
	const [fileUpload, setFileUplaod] = useState(false)
	const [selectedHeaderCheckbox, setSelectedHeaderCheckbox] = useState({})
	const [widgetHeaderListData, setWidgetHeaderList] = useState(widgetHeaderList)
	const [exportFlag, setExportFlag] = useState(false)
	useEffect(() => {
		setWidgetHeaderList(widgetHeaderList)
	}, [widgetHeaderList])
	useEffect(() => {
		let selectedHeaderCheckboxData = Object.values(selectedHeaderCheckbox)
		const filteredArray = widgetHeaderList?.filter(
			item =>
				!selectedHeaderCheckboxData.some(
					comparisonItem => comparisonItem.value === item.header_id
				)
		)
		setWidgetHeaderList(filteredArray)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedHeaderCheckbox])
	const headersArray = widgetHeaderList?.map((item, i) => ({
		label: item.header_name,
		key: item.header_name,
		value: item.header_id,
		flag: i,
	}))
	const {
		isOpen: isSendEmail,
		onOpen: onOpenSendEmail,
		onClose: onCloseSendEmail,
	} = useDisclosure()
	const { isOpen, onClose, onOpen } = useDisclosure()
	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

	let componentRef = React.useRef()

	useEffect(() => {
		onAssignReportingManagerAccess(buildReportingManagerParam(0))
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityStatus(data)
				},
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const hash = objectHash(widgetData)
		setWidgetDataHash(hash)
		const filters = drilldownFilterStatus?.[hash]?.checkbox || {}
		if (Object.keys(filters).length) {
			toast({
				title: 'Filters restored!',
				status: 'info',
				position: 'top',
				duration: 1000,
				isClosable: true,
			})
		}
		setSelectedCheckbox(filters)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetData])

	useEffect(() => {
		if (!!widgetData) {
			const inlineTagTypeId = JSON.parse(widgetData.filter_tag_type_id)
			loadWidgetHeaders(
				inlineTagTypeId[0].tag_type_id,
				queryFilters.selectedOrganization?.value
			)
			reloadWidgetDrilldown()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetData])

	useEffect(() => {
		if (
			widgetRecords.length < drilldownFilterStatus?.[widgetDataHash]?.pageLoaded
		)
			reloadWidgetDrilldown(false, widgetRecords.length)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetRecords])

	useEffect(() => {
		if (!!widgetExportList && widgetExportList.exceeds) {
			setIsExceedOpen(true)
		}
	}, [widgetExportList])

	useEffect(() => {
		resetFiltersDrilldown()
		if (!!search) {
			onDebounceSearch()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search])

	useEffect(() => {
		setTimeout(() => {
			reloadWidgetDrilldown(false, 0, true)
		}, 2000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicFieldsList])

	useEffect(() => {
		if (!!widgetData) {
			const inlineTagTypeId = JSON.parse(widgetData.filter_tag_type_id)
			const isJson2 =
				!!widgetHeaderList &&
				widgetHeaderList
					.filter(f => f.db_field_flag_is_json === 2)
					.filter(Boolean)
					.reduce((a, s) => s, { db_field_flag_is_json: 0 })
			if (isJson2.db_field_flag_is_json === 2) {
				dynamicFields({
					header_field_name: isJson2.db_field_name,
					tag_type_id: inlineTagTypeId[0].tag_type_id,
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetHeaderList, widgetData])

	// /*
	//  * Need to trigger reload of widget drilldown data, when widgetData changes.
	//  * This will be useful, when filters are visible in the drilldown view, and then the
	//  * user changes the filter, which should update the drilldown data.
	//  */
	// useEffect(() => {
	// 	reloadWidgetDrilldown(false, 0, true)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [widgetData])

	const callbackFocusRef = useCallback(inputElement => {
		if (inputElement) {
			inputElement.focus()
		}
	}, [])

	const onDebounceSearch = debounce(() => {
		reloadWidgetDrilldown()
	}, 500)

	const resetFiltersDrilldown = () => {
		const filters = drilldownFilterStatus?.[widgetDataHash]
		if (filters)
			dispatchDrilldown({
				type: drilldownConst.SAVE_FILTER_STATUS,
				payload: {},
			})
	}

	const timeColor = datetime => {
		let isDelayed =
			new Date(datetime?.split(' ').join('T')).setHours(0, 0, 0, 0) <
			new Date().setHours(0, 0, 0, 0)
		if (!!isDelayed) {
			return 'text_red'
		} else {
			return ''
		}
	}

	const reloadWidgetDrilldown = (
		isExport = false,
		page = 0,
		isReload = false
	) => {
		if (isExport) {
			loadWidgetExportData({
				widgetData,
				searchStr: search.trim(),
				isExport,
				filter_organization_id: queryFilters?.selectedOrganization?.value,
			})
		} else if (widgetData.widget_type_id === 346) {
			loadSMEDrillDownData(
				{
					organization_id: widgetData.organization_id,
					asset_id: widgetData.asset_id,
					flag: 1,
					summary_id: 7,
				},
				{
					onSuccess: data => {
						let widgetDataRecords = []
						if (!!search || !!isReload) {
							widgetDataRecords = data
							setScrollAtBottom(false)
						} else {
							widgetDataRecords = widgetRecords.concat(data)
						}
						setWidgetRecords(widgetDataRecords)
					},
				}
			)
		} else {
			if (!isWidgetDataLoading && (!!search || !!isReload || !!hasMoreData)) {
				loadWidgetDrillDownData(
					{
						widgetData,
						searchStr: search.trim(),
						page,
						filter_organization_id: queryFilters.selectedOrganization?.value,
					},
					{
						onSuccess: data => {
							// let widgetDataRecords = [];
							if (!!search || !!isReload) {
								// widgetDataRecords = data;
								setWidgetRecords(data)
								setScrollAtBottom(false)
							} else {
								// widgetDataRecords = widgetRecords.concat(data);
								setWidgetRecords(prevState => {
									return [...prevState, ...data]
								})
							}

							// setWidgetRecords(widgetDataRecords);

							if (data.length === 0) setHasMoreData(false)
						},
					}
				)
			}
		}
	}

	useEffect(() => {
		if (isClickArrow) {
			//setTimeout(() => {
			sortRecords(localStorage.getItem('sortFieldName'), true)
			//}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClickArrow, widgetRecords]) //simha
	const sortRecords = useCallback(
		(field, fromArrow = false) => {
			let format = null

			if (fromArrow) {
				format = localStorage.getItem('sortType')
			} else {
				if (field === sortField) {
					if (sortType === null || sortType === 'asc') {
						format = 'desc'
					} else {
						format = 'asc'
					}
				} else {
					format = 'asc'
				}
			}
			localStorage.setItem('sortType', format)
			widgetRecords.sort((nextElement, element) => {
				let a = field?.includes('column')
					? JSON.parse(element['widget_additional_fields'])?.[field]
					: element[field]
				let b = field?.includes('column')
					? JSON.parse(nextElement['widget_additional_fields'])?.[field]
					: nextElement[field]

				// equal items sort equally
				if (a === b) {
					return 0
				}

				// nulls sort after anything else
				if (a === undefined) {
					return -1
				}
				if (b === undefined) {
					return 1
				}

				// otherwise, if we're ascending, lowest sorts first
				if (format === undefined || format === 'asc') {
					return a < b ? 1 : -1
				}

				// if descending, highest sorts first
				return a < b ? -1 : 1
			})

			setSortType(format)
			setSortField(field)
			//setIsClickArrow(false)
		},
		[widgetRecords, sortType, sortField]
	)

	const renderWidgetDrilldownHeader = () => {
		let theads =
			!!widgetHeaderListData && widgetHeaderListData.length
				? widgetHeaderListData
						.map((header, i) => {
							return (
								<>
									<TH
										header={header}
										sortRecords={sortRecords}
										key={i + 1}
										sortField={sortField}
										sortType={sortType}
										timelineFilter={updateTimelineFilter[header.db_field_name]}
										selectedCheckbox={selectedCheckbox}
										setSelectedCheckbox={setSelectedCheckbox}
										setSortFieldName={setSortFieldName}
									>
										{header.header_name}
									</TH>
								</>
							)
						})
						.sort(header => header.sequence_id)
				: null
		let tableHead = (
			<TR>
				<TH>#</TH>
				{!!queryFilters.selectedApplication.field_edit_enabled &&
					![0].includes(
						queryFilters.selectedApplication.field_edit_enabled
					) && <TH>Actions</TH>}

				{theads}
				{/* <TH>Actions</TH> */}
			</TR>
		)
		return tableHead
	}

	const participantInfo = (activity_id, field_id, activity_lead_asset_id) => {
		const {
			account_id,
			asset_first_name,
			asset_id,
			asset_image_path,
			asset_last_name,
			asset_phone_number,
			asset_phone_number_code,
			operating_asset_first_name,
			organization_id,
			workforce_id,
		} = authData

		return activity_lead_asset_id === asset_id
			? []
			: [
					{
						access_role_id: 22,
						asset_datetime_last_seen: '1970-01-01 00:00:00',
						activity_id: activity_id,
						asset_type_category_id: 3,
						asset_type_id: 133001,
						field_id: field_id,
						account_id,
						asset_first_name,
						asset_id,
						asset_image_path,
						asset_last_name,
						asset_phone_number,
						asset_phone_number_code,
						log_asset_id: asset_id,
						message_unique_id: getMessageUniqueId(),
						operating_asset_first_name,
						organization_id,
						workforce_id,
					},
			  ]
	}

	const timelineInline = (formFieldsData, form_name, form_id) => {
		let content = 'Form Submitted'
		let body = form_name
		return {
			mail_body: body,
			asset_reference: [
				{
					account_id: '',
					organization: '',
					asset_id: '',
					asset_first_name: '',
					asset_type_category_id: '',
					asset_last_name: '',
					asset_image_path: '',
				},
			],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			form_approval_field_reference: [],
			subject: body,
			attachments: [],
			content: content,
			form_id: form_id,
			form_submitted: formFieldsData,
		}
	}

	const paramAdditional = (value, widget_additional_fields) => {
		let parse = !!widget_additional_fields
			? JSON.parse(widget_additional_fields)
			: {}
		return {
			...parse,
			form_submitted: [value],
		}
	}

	const prepareFormSaveParams = ({ activity, field, value }) => {
		const { activity_id, activity_type_id, activity_lead_asset_id } = activity
		const { form_id, field_id, form_name } = field
		const { activity_type_id: form_workflow_activity_type_id } = activityType
		const { activity_status_id } = activityStatus
		let title = `${formatDDMMYYYYHHMM()}-${form_name}`
		let formFieldsData = [value]
		return {
			activity_id: !!activity_id ? activity_id : 0,
			channel_activity_id: !!activity_id ? activity_id : 0,
			activity_title: title,
			activity_description: title,
			activity_inline_data: JSON.stringify(formFieldsData),
			// data_entity_inline: JSON.stringify(formFieldsData),
			activity_timeline_collection: JSON.stringify(
				timelineInline(formFieldsData, form_name, form_id)
			),
			activity_participant_collection: JSON.stringify(
				participantInfo(activity_id, field_id, activity_lead_asset_id)
			),
			form_id: form_id,
			activity_form_id: form_id,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			activity_type_id: form_workflow_activity_type_id,
			is_refill: 0,
			form_workflow_activity_type_id: activity_type_id,
			generated_group_account_name: null,
			generated_account_code: null,
			activity_title_expression: null,
			gst_number: '',
			pan_number: '',
			activity_datetime_end: getCurrentDateUTC(),
			activity_datetime_start: getCurrentDateUTC(),
			activity_status_id,
			lead_asset_first_name: !!activity
				? activity.activity_lead_operating_asset_first_name
				: '',
			lead_asset_type_id:
				!!activity && activity.activity_lead_asset_type_id
					? activity.activity_lead_asset_type_id
					: null,
			lead_asset_id: !!activity ? activity.activity_lead_asset_id : '',
		}
	}

	const handleChange = (e, field, activity) => {
		let {
			form_id,
			field_id,
			field_reference_id,
			field_name,
			data_type_id,
			data_type_category_id,
		} = field
		let value = {
			form_id,
			field_id,
			field_reference_id,
			field_name,
			field_data_type_id: data_type_id,
			field_data_type_category_id: data_type_category_id,
			data_type_combo_id: e.data_type_combo_id,
			data_type_combo_value: 0,
			field_value: e.value,
			message_unique_id: getMessageUniqueId(),
		}
		setFreezeAct(e => [...e, activity.activity_id])
		mutateSubmitForm(
			{
				...prepareFormSaveParams({ field, activity, value }),
				isOrigin: false,
				form_api_activity_type_category_id:
					activity.activity_type_category_id || 48,
				form_api_activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async data => {
					if (!!data.form_transaction_id) {
						value['form_transaction_id'] = data.form_transaction_id
						mutateAdditionalFieldUpdate(
							{
								widget_additional_fields: JSON.stringify(
									paramAdditional(value, activity.widget_additional_fields)
								),
								activity_id: activity.activity_id,
							},
							{
								onSuccess: async data => {
									console.log('data', data)
									setTimeout(() => {
										setHasMoreData(true)
										reloadWidgetDrilldown(false, 0, true)
										setSelectedCheckbox({})
										resetFiltersDrilldown()
									}, 2000)
								},
								onError: async err => {
									console.log('Error', err)
								},
							}
						)
					}
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const getCustomColumnData = (headerList, listItem) => {
		return headerList.db_field_flag_is_json === 2
			? !!dynamicFieldsList && !!dynamicFieldsList?.length
				? dynamicFieldsList
						.filter(f => f.data_type_id === 33)
						.map((field, index) => {
							let options = !!field.options ? [...field.options] : []
							const { activity_creator_asset_id, activity_id } = listItem
							const { asset_id } = authData
							const flag_access_setting = field.flag_access_setting
							if (flag_access_setting === 0) {
								return (
									<Select
										key={index}
										id={field.field_name.split(' ').join('-')}
										options={options}
										onChange={e => handleChange(e, field, listItem)}
										placeholder={`Select`}
										borderRadius='md'
										isDisabled={!!freezeAct.includes(activity_id)}
										size='sm'
										maxMenuHeight='250'
										menuPlacement='auto'
										styles={customStyles}
									/>
								)
							} else if (flag_access_setting === 1) {
								if (activity_creator_asset_id !== asset_id) {
									return (
										<Select
											key={index}
											id={field.field_name.split(' ').join('-')}
											options={options}
											onChange={e => handleChange(e, field, listItem)}
											placeholder={`Select`}
											borderRadius='md'
											isDisabled={!!freezeAct.includes(activity_id)}
											size='sm'
											maxMenuHeight='250'
											menuPlacement='auto'
											styles={customStyles}
										/>
									)
								} else {
									return (
										<Select
											key={index}
											id={field.field_name.split(' ').join('-')}
											options={[]}
											placeholder={`Select`}
											borderRadius='md'
											isDisabled={true}
											size='md'
											maxMenuHeight='250'
										/>
									)
								}
							} else {
								return null
							}
						})
				: null
			: headerList.db_field_flag_is_json !== 1
			? headerList.data_type_id === 1
				? getCustomFormatttedDate(
						listItem[headerList.db_field_name],
						headerList.conversion_format
				  )
				: headerList.data_type_id === 5
				? getValueInMillions(
						listItem[headerList.db_field_name],
						headerList.conversion_format
				  )
				: listItem[headerList.db_field_name]
			: headerList?.flag_is_reference === 1
			? getInlineReferenceFields(
					headerList,
					listItem[headerList.db_field_json],
					handleReferenceData
			  )
			: headerList.data_type_id === 81
			? getInlineLeaveFields(
					headerList,
					listItem[headerList.db_field_json],
					leaveJson
			  )
			: getInlineFields(headerList, listItem[headerList.db_field_json])
	}

	const getRagStatusColor = (headerList, listItem) => {
		return getInlineRagStatus(headerList, listItem[headerList.db_field_json])
	}

	const handleReferenceData = data => {
		dashboardDispatch({
			type: dashboardConst.SET_TOOLTIP_LOADER,
			payload: true,
		})
		if (!isNaN(data?.activity_id)) {
			mutateActivity(
				{
					activity_id: data?.activity_id,
				},
				{
					onSuccess: async data => {
						let act_data = {}
						if (data?.activity_id) {
							act_data = {
								activity_id: data.activity_id,
								activity_title: data.activity_title,
								tag_type_name: data.tag_type_name,
								activity_status_name: data.activity_status_name,
							}
							dashboardDispatch({
								type: dashboardConst.SET_TOOLTIP_ACTIVITY,
								payload: act_data,
							})
						}
						dashboardDispatch({
							type: dashboardConst.SET_TOOLTIP_LOADER,
							payload: false,
						})
					},
				}
			)
		}
	}

	const leaveJson = e => {
		if (e.length) {
			setLeaveData(e)
			onOpen()
		}
	}

	const toReturnTotalLeaves = data => {
		return data.map(a => Number(a.leave_applied)).reduce((a, b) => a + b)
	}

	const updateTimelineFilter = useMemo(() => {
		const toReturnObj = data => {
			let obj = {}
			Object.keys(data).forEach(el => {
				if (el === 'Blank') {
					obj = {
						Blank: {},
						...obj,
					}
					obj[el] = data[el]
				} else {
					obj[el] = data[el]
				}
			})

			return obj
		}

		const toReturnFinalobj = data => {
			let newObj = Object.keys(data)
			let finalObj = {}
			newObj.forEach(val => {
				finalObj[val] = toReturnObj(data[val])
			})

			return finalObj
		}

		let label
		let updatedTimelineFilter = {}
		widgetRecords?.map(recordList => {
			widgetHeaderList?.map(headerList => {
				label = getCustomColumnData(headerList, recordList)
				if (headerList.db_field_flag_is_json !== 2) {
					const columnName = headerList.db_field_name
					label = !!label ? label : 'Blank'
					updatedTimelineFilter[columnName]
						? (updatedTimelineFilter[columnName][label] = {
								value: label,
								label,
								headerList,
						  })
						: (updatedTimelineFilter[columnName] = {
								[label]: {
									value: label,
									label,
									headerList,
								},
						  })
				}
				return true
			})
			return true
		})
		return toReturnFinalobj(updatedTimelineFilter)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [widgetRecords])

	const getFilteredData = () => {
		const rows = widgetRecords.filter(item => {
			const keyArr = Object.keys(selectedCheckbox)
			if (!!keyArr.length) {
				return keyArr.reduce(function (acc, cur) {
					let label = getCustomColumnData(
						selectedCheckbox[cur][Object.keys(selectedCheckbox[cur])[0]]
							.headerList,
						item
					)
					if (
						acc &&
						selectedCheckbox[cur].hasOwnProperty(!!label ? label : 'Blank')
					)
						return true
					else return false
				}, true)
			}
			return true
		})
		return rows
	}

	const ragColor = color => {
		if (!!color && color === 'green') {
			return 'bg-green'
		} else if (!!color && color === 'red') {
			return 'bg-red'
		} else if (!!color && color === 'amber') {
			return 'bg-amber'
		} else {
			return ''
		}
	}
	const ReadMoreText = ({ text, maxLength }) => {
		const [isExpanded, setIsExpanded] = useState(false)
		const toggleReadMore = () => {
			setIsExpanded(!isExpanded)
		}
		const displayText = isExpanded ? text : text?.slice(0, maxLength)

		return (
			<div>
				<p>{displayText} </p>
				{text?.length > maxLength && (
					<button
						alignSelf='flex-start'
						cursor='pointer'
						fontSize='sm'
						display='inline'
						color='blue.300'
						ml={1}
						onClick={toggleReadMore}
					>
						{isExpanded ? 'read less' : 'read more'}
					</button>
				)}
			</div>
		)
	}
	const [isExpandedLink, setIsExpandedLink] = useState(false)
	const toggleReadMoreLink = () => {
		setIsExpandedLink(!isExpandedLink)
	}

	const renderWidgetDrilldownData = () => {
		let tableItems = []
		const selectedIndex = drilldownFilterStatus?.[widgetDataHash]?.selectedIndex
		if (!!widgetRecords && widgetRecords.length) {
			tableItems.push(
				getFilteredData().map((listItem, i) => {
					return (
						<TR
							key={i + 1}
							ref={
								selectedIndex === listItem.activity_id ? callbackFocusRef : null
							}
							tabIndexRequired={selectedIndex === listItem.activity_id}
							classes={
								selectedIndex === listItem.activity_id
									? 'table-row-selected'
									: null
							}
						>
							<TD>{i + 1}</TD>
							{!!queryFilters.selectedApplication.field_edit_enabled &&
								![0].includes(
									queryFilters.selectedApplication.field_edit_enabled
								) && (
									<TD>
										<HStack>
											{[1, 3].includes(
												queryFilters.selectedApplication.field_edit_enabled
											) && (
												<Tooltip label='Edit Submitted Fields'>
													<IconButton
														bg='white'
														aria-label='close'
														size='sm'
														borderRadius='md'
														boxShadow='md'
														_focus={{
															bg: 'secondary',
														}}
														_hover={{
															bg: 'secondary',
														}}
														icon={<Icon as={HiOutlinePencilAlt} w={4} h={4} />}
														onClick={() => {
															// dataLayerTagManager('button_click', {
															// 	viewName: 'Drilldown Table',
															// 	buttonName: 'Edit WorkFlow',
															// })
															onEditClick(listItem, 'edit')
														}}
													/>
												</Tooltip>
											)}

											{[2, 3].includes(
												queryFilters.selectedApplication.field_edit_enabled
											) && (
												<Tooltip label={' Submit New Fields'}>
													<IconButton
														bg='white'
														aria-label='close'
														size='sm'
														mt='3'
														borderRadius='md'
														boxShadow='md'
														_focus={{
															bg: 'secondary',
														}}
														_hover={{
															bg: 'secondary',
														}}
														icon={<Icon as={FiBarChart2} w={4} h={4} />}
														onClick={() => {
															// dataLayerTagManager('button_click', {
															// 	viewName: 'Drilldown Table',
															// 	buttonName: 'Dashboard WorkFlow',
															// })
															onEditClick(listItem, 'dashboard')
														}}
													/>
												</Tooltip>
											)}
										</HStack>
									</TD>
								)}
							{!!widgetHeaderListData && widgetHeaderListData.length
								? widgetHeaderListData.map((headerList, index) => {
										let value = getCustomColumnData(headerList, listItem)
										const ragStatus = getRagStatusColor(headerList, listItem)
										let checkIsUrl = false
										let hyperLink = false
										let isMultiple = false

										if (
											value &&
											value?.toString().includes('file_name') &&
											value?.toString().includes('file_url')
										) {
											const jsonArray = JSON.parse(value)
											isMultiple = true
											value = jsonArray
										} else if (!!value && typeof value === 'string') {
											let url = validURL(value)
											if (url && value.toString().includes('ltts-worlddesk')) {
												checkIsUrl =
													!!value && typeof value === 'string'
														? hasImageOrVideoUrl(value)
														: ''
												// } else if (url && hasImageOrVideoUrl(value)) {
												// 	checkIsUrl = true
											} else if (url && value.startsWith('http')) {
												hyperLink = true
											}
										}
										return (
											<>
												<TD
													key={index}
													classes={
														headerList.db_field_name === 'activity_title'
															? selectedId === listItem.activity_id
																? 'cursor-wait text-weight-200 text-left'
																: `cursor-pointer text-bold text-left ${timeColor(
																		listItem?.activity_datetime_end_deferred
																  )}`
															: !!ragStatus
															? ragColor(ragStatus)
															: null
													}
													onClickHandler={() => {
														if (
															headerList.db_field_name === 'activity_title' &&
															!isMobileView
														) {
															// dataLayerTagManager('button_click', {
															// 	viewName: 'Drilldown Table',
															// 	buttonName: 'Select Workflow',
															// })
															checkAssetPrivilege(listItem)
														}
													}}
												>
													{checkIsUrl ? (
														<Text
															onClick={() =>
																checkIsUrl ? downloadAwsFile(value) : null
															}
															fontWeight='800'
															cursor='pointer'
															color='brand.800'
															textDecoration='underline'
														>
															{value?.split('/').pop()}
														</Text>
													) : isMultiple ? (
														value?.map((item, index) => (
															<React.Fragment key={index}>
																<Text
																	onClick={() =>
																		(checkIsUrl = hasImageOrVideoUrl(
																			item?.file_url
																		)
																			? downloadAwsFile(item?.file_url)
																			: null)
																	}
																	fontWeight='800'
																	cursor='pointer'
																	color='brand.800'
																	textDecoration='underline'
																>
																	{item?.file_url?.split('/').pop()}
																</Text>
																{index < value.length - 1 && ','}
															</React.Fragment>
														))
													) : hyperLink ? (
														<>
															{/* <Text
																fontWeight='800'
																cursor='pointer'
																color='brand.800'
																textDecoration='underline'
																dangerouslySetInnerHTML={{
																	__html: replaceURL(value),
																}}
															/> */}

															<Text>
																<a
																	href={value && value}
																	target='_blank'
																	rel='noopener noreferrer'
																	style={{ color: 'green' }}
																>
																	{isExpandedLink
																		? value
																		: value?.toString().slice(0, 50)}
																</a>

																{value?.length > 50 && (
																	<button
																		alignSelf='flex-start'
																		cursor='pointer'
																		fontSize='sm'
																		display='inline'
																		color='blue.300'
																		ml={1}
																		onClick={toggleReadMoreLink}
																	>
																		{isExpandedLink ? 'read less' : 'read more'}
																	</button>
																)}
															</Text>
														</>
													) : value?.length > 100 ? (
														<ReadMoreText
															text={value && value?.toString()}
															maxLength={100}
														/>
													) : (
														value && value
													)}
												</TD>
											</>
										)
								  })
								: null}
							{/* <TD
								onClickHandler={() => {

									dataLayerTagManager('button_click', {
										viewName: 'Drilldown Table',
										buttonName: 'Edit WorkFlow',
									})
									onEditClick(listItem)

								}}

							><Button colorScheme='teal' size='xs'>
									Edit
								</Button></TD> */}
						</TR>
					)
				})
			)
		}
		if (isWidgetDataLoading) {
			let loader = [1, 2, 3].map(i => (
				<TR key={i}>
					<TD>
						<SkeletonText mt='4' />
					</TD>
					{!!widgetHeaderList && widgetHeaderList.length
						? widgetHeaderList.map((_, index) => (
								<TD key={index}>
									<SkeletonText mt='4' />
								</TD>
						  ))
						: null}
				</TR>
			))
			if (scrollAtBottom) {
				tableItems = [...tableItems, ...loader]
			} else {
				tableItems = loader
			}
		}
		return tableItems
	}
	const renderExportData = () => {
		let dataSet = []
		widgetExportList.exports.length &&
			widgetExportList.exports.map(listItem => {
				dataSet.push(
					parseDataForExcelExport(widgetHeaderList, listItem, exportFlag)
				)
				return null
			})
		return (
			<ExcelFile filename={widgetData.activity_title} hideElement={true}>
				<ExcelSheet data={dataSet} name='Drilldown Data'>
					{!!widgetHeaderList &&
						widgetHeaderList.map(headerItem => (
							<ExcelColumn
								label={headerItem.header_name}
								value={headerItem.db_field_name}
							/>
						))}
				</ExcelSheet>
			</ExcelFile>
		)
	}

	const onAssignReportingManagerAccess = payload => {
		dispatch({
			type: sharedConst.ASSIGN_REPORTING_MANAGER_ACCESS,
			accessType: payload ? payload.accessType : 0,
		})
	}

	const checkDottedLineManagerAccess = item => {
		let payload = {
			organization_id: userData.organization_id,
			asset_id: userData.asset_id,
			activity_id: item.activity_id,
			flag: 1,
			asset_token_auth: userData.asset_token_auth,
			activity_creator_asset_id: item.activity_creator_asset_id,
		}
		mutateCheckDottedLineManagerAccess(payload, {
			onSuccess: data => {
				if (Array.isArray(data) && !data[0].hasOwnProperty('query_status')) {
					onAssignReportingManagerAccess(buildReportingManagerParam(1))
				}
				onSelectActivity(item)
			},
		})
	}

	const checkAssetPrivilege = item => {
		dispatchDrilldown({
			type: drilldownConst.SAVE_FILTER_STATUS,
			payload: {
				[widgetDataHash]: {
					checkbox: selectedCheckbox,
					selectedIndex: item.activity_id,
					pageLoaded: widgetRecords.length,
				},
			},
		})
		setSelectedId(item.activity_id)
		//This flag must be enabled in order for hierchial manager feature to work
		if (userData.organization_flag_enable_manager_proxy !== 1) {
			onSelectActivity(item)
		} else {
			const { operating_asset_first_name, asset_id } = userData
			let creator = {
				operating_asset_first_name,
				asset_id,
			}
			let payload = {
				creator,
				flag: 1,
			}
			mutateLoadReportees(payload, {
				onSuccess: data => {
					if (!data.length) {
						checkDottedLineManagerAccess(item)
					} else {
						//Check for creator
						var isReporteeCreator = data.find(asset => {
							return asset.asset_id === item.activity_creator_asset_id
						})
						if (isReporteeCreator) {
							onAssignReportingManagerAccess(buildReportingManagerParam(1))
							onSelectActivity(item)
						} else {
							//Check for lead
							var isReporteeLead = data.find(asset => {
								return asset.asset_id === item.activity_lead_asset_id
							})
							if (isReporteeLead) {
								onAssignReportingManagerAccess(buildReportingManagerParam(2))
								onSelectActivity(item)
							} else {
								checkDottedLineManagerAccess(item)
							}
						}
					}
				},
			})
		}
	}

	const onEditClick = (item, type) => {
		onOpenSendEmail()
		setEditItem(item)
		setModelType(type)
		getFieldConfig(item)
		loadAccessSelectActivity(
			{
				activity_type_id: item?.activity_type_id,
				flag: 0,
			},
			{
				onSuccess: async data => {
					setloadSuperAdmins(data)
				},
				onError: async err => {
					console.log('err', err)
				},
			}
		)
	}

	const handleAssetReferenceField = async value => {
		const data = await mutateAssetAsync({
			workforce_id: 0,
			flag_filter: 0,
			activity_id: activity ? activity.activity_id : 0,
			search_string: value,
			asset_type_category_id: 0,
			asset_type_id: 0,
		})
		return data ? data[0] : null
	}

	const handleWorkflowReferenceField = async (value, header, currFields) => {
		const fieldDetails = header.field_details?.[0] || {}
		const {
			activity_type_id,
			tag_id,
			tag_type_id,
			flag_participating,
			activity_status_type_id,
			activity_type_category_id,
			activity_status_id,
			flag_dependent,
			dependent_field_id,
			dependent_field_service_param_name,
		} = fieldDetails.field_inline_data?.workflow_reference_restriction

		let params = {
			search_string: value,
			flag_status: 0,
			flag_participating: flag_participating || 0,
			activity_type_category_id,
			activity_type_id,
			tag_id,
			tag_type_id,
			activity_status_type_id,
			activity_status_id,
			page_limit: 10,
			page_start: 0,
			reference_asset_id: '',
			isElasticSearchEnabled: authData.organization_flag_elasticsearch_enabled,
		}

		const allFields = !!currFields[fieldDetails.form_id]
			? currFields[fieldDetails.form_id]
			: {}

		if (flag_dependent && !!allFields[dependent_field_id]) {
			params = {
				...params,
				flag_dependent,
				[`${dependent_field_service_param_name}`]:
					allFields[dependent_field_id].field_value?.details?.activity_id || 0,
			}
		}
		const data = await loadWorkflowAsync(params)
		return data ? data[0] : null
	}

	const calculateAllFieldValues = async (data, item) => {
		let formId =
			data.headers.length > 0 ? data.headers[0]?.form_details?.form_id : null
		let fields = {}
		fields[formId] = {}

		const selectionFieldsRef = []
		data.headers.forEach(header => {
			const value1 = getCustomColumnData(header, item)

			if (!!header.field_details && header.field_details.length > 0) {
				let fieldData = header.field_details[0]
				let field_value = value1 ?? null
				if (header.field_details?.[0]?.data_type_id === 59) {
					selectionFieldsRef.push({
						value: value1,
						header,
					})
				} else if (header.field_details?.[0]?.data_type_id === 57) {
					selectionFieldsRef.push({
						value: value1,
						header,
					})
				}

				let data = {
					...fieldData,
					sort_id: fieldData?.sort_id,
					field_value,
					form_transaction_id: header?.form_details?.form_transaction_id,
				}
				fields[formId][`${fieldData.field_id}`] = data
			}
		})

		/* Looping through all those fields, whose data is dependent on API calls, specifically
		 * selection list fields.
		 * Need to do API calls sequentially, since some of the fields data might be dependent
		 * on previous fields data.
		 */
		for (let fieldRef of selectionFieldsRef) {
			const { value, header } = fieldRef
			let fieldData = header.field_details[0]
			let field_value = null

			if (header.field_details?.[0]?.data_type_id === 59) {
				field_value = await handleAssetReferenceField(value)
			} else if (header.field_details?.[0]?.data_type_id === 57) {
				field_value = await handleWorkflowReferenceField(value, header, fields)
			}
			fields[formId][`${fieldData.field_id}`].field_value = field_value
		}
		setTimeout(() => {
			dispatchFormFields({
				type: smartFormConst.SET_FIELD_VALUES,
				formId: formId,
				fields,
			})
		})

		setHeaderFieldType(data.headers)
	}

	const getFieldConfig = item => {
		const payload = {
			organization_id: userData?.organization_id,
			asset_id: userData?.asset_id,
			auth_asset_id: userData?.auth_asset_id,
			asset_type_id: authData?.asset_type_id,
			tag_type_id: widgetHeaderList && widgetHeaderList[0]?.tag_type_id,
			asset_token_auth: userData?.asset_token_auth,
			activity_type_id: item?.activity_type_id,
			account_id: item?.account_id,
			workflow_activity_id: item?.activity_id,
		}
		loadFeilds(payload, {
			onSuccess: async data => {
				if (!!data) {
					// let fieldsInfo = data.headers.map(item=> !!item.field_details && Array.isArray(item.field_details) && item.field_details.length > 0 ? item.field_details[0] : null)
					let formId =
						data.headers.length > 0
							? data.headers[0]?.form_details?.form_id
							: null

					let fields = {}
					fields[formId] = {}
					if (data.headers.length > 0) {
						await calculateAllFieldValues(data, item)
					}
				}
			},
		})
	}
	const buildReportingManagerParam = flag => {
		return {
			accessType: flag,
		}
	}

	const onSelectActivity = useCallback(
		activity => {
			dispatch({
				type: sharedConst.ACTVITY_SELECTED,
				payload: activity,
			})
			loadActivity(
				{
					flag: 0,
					is_active: 0,
					is_due: 0,
					is_search: 1,
					is_unread: 0,
					limit_value: 30,
					page_limit: 30,
					page_start: 0,
					queue_id: 0,
					search_string: activity.activity_cuid_1,
					sort_flag: 4,
					start_from: 0,
					status_type_id: 0,
				},
				{
					onSuccess: res => {
						let data = res.list.map(({ asset_participant_access_id }) => ({
							asset_participant_access_id,
						}))
						dispatch({
							type: sharedConst.SET_PARTICIPANT_ACCESS_ID,
							accessID: data[0]?.asset_participant_access_id,
						})
						onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_WORKSPACE)
						//onToggle()
					},
				}
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sharedConst, WIDGET_WORKSPACE_MODE]
	)

	const handleScrollUpdate = values => {
		// const { scrollTop, scrollHeight, clientHeight, scrollYPossible } = values
		// if (
		// 	scrollHeight - clientHeight === Math.round(scrollTop) &&
		// 	scrollYPossible
		// ) {
		setScrollAtBottom(true)
		reloadWidgetDrilldown(false, widgetRecords.length)
		// }
	}

	const onCellClicked = e => {
		let activity = widgetRecords.find(
			f =>
				f.activity_cuid_1 === e.data.activity_cuid_1 &&
				f.activity_title === e.data.activity_title
		)
		if (!!activity) {
			checkAssetPrivilege(activity)
		}
	}

	const onValidate = valid => {
		setIsFieldValidate(valid)
		//validateValue(valid)
		if (valid) {
			setError('')
		}
	}

	const onError = err => {
		setIsFieldValidate(false)
		//validateValue(false)
		setError(err)
	}

	let dataSet = []
	!!widgetRecords &&
		widgetRecords.length &&
		widgetRecords
			.filter(f => f.activity_type_category_id === 66)
			.map(listItem => {
				dataSet.push({
					activity_cuid_1: listItem.activity_cuid_1,
					activity_cuid_2: listItem.activity_cuid_2,
					activity_title: listItem.activity_title,
				})
				return null
			})
	const { asset_id, asset_flag_super_admin, organization_ai_bot_enabled } =
		authData
	const isSuperAdmin = () => {
		const superAdmin = asset_flag_super_admin === 1 ? true : false
		let isSuperAdminCheck
		let isUserHaveAccess
		if (!!loadSuperAdmins.length) {
			isSuperAdminCheck = loadSuperAdmins.find(
				item => item.asset_type_id === authData.asset_type_id
			)
			isUserHaveAccess = loadSuperAdmins.find(
				item => item.asset_id === authData.asset_id
			)
		}
		if (superAdmin || !!isSuperAdminCheck || !!isUserHaveAccess) {
			return true
		} else {
			return false
		}
	}
	const activityEnable = (owner_access = null) => {
		const isLead = !!editItem?.activity_lead_asset_id
			? authData.asset_id === editItem?.activity_lead_asset_id
			: false
		// const superAdmin = asset_flag_super_admin == 1 ? true : false;
		let participant = participants.find(
			item => item.asset_id === authData.asset_id
		)
		const isOwner =
			!!isReportingManagerOwner ||
			!!isReportingManagerLead ||
			(owner_access === null
				? !!participant
					? participant.asset_flag_is_owner === 1
						? true
						: false
					: false
				: owner_access)
		/* let isSuperAdminCheck;
			let isUserHaveAccess;
			if (!!loadSuperAdmins.length) {
			  isSuperAdminCheck = loadSuperAdmins.find(
				item => item.asset_type_id === authData.asset_type_id
			  );
			  isUserHaveAccess = loadSuperAdmins.find(
				item => item.asset_id === authData.asset_id
			  );
			}*/
		if (isOwner || isLead || isSuperAdmin()) {
			return true
		} else {
			return false
		}
	}

	const onSubmitEditData = async () => {
		if (Object.keys(fields).length > 0) {
			let finalArray = []
			let values = Object.values(fields)
			values.forEach(item => {
				finalArray.push(...Object.values(item))
			})
			for (const item of finalArray) {
				await new Promise(resolve => setTimeout(resolve, 250))
				if (!!item.final && !!item.form_transaction_id) {
					saveEditedFields(
						{
							form_transaction_id: item.form_transaction_id,
							form_id: item.final.form_id,
							field_id: item.final.field_id,
							activity_inline_data: JSON.stringify([
								{
									form_transaction_id: item.form_transaction_id,
									...item.final,
								},
							]),
							activity_id: editItem?.activity_id,
							channel_activity_id: editItem?.activity_id,
							activity_title: editItem?.activity_title,
						},
						{
							onSuccess: async data => {
								// console.log('single edit data', data)
							},
						}
					)
				}
			}
			onCloseSendEmail()
			setTimeout(() => {
				setHasMoreData(true)
				reloadWidgetDrilldown(false, 0, true)
				setSelectedCheckbox({})
				resetFiltersDrilldown()
			}, 1500)
		}
	}

	const [workspaceMode, setworkspaceMode] = useState(null)

	const onFormRefill = (status = true, isBEdit = false) => {
		setRefillView(true)
		setisBulkEdit(isBEdit)
		setworkspaceMode(WORKSPACE_MODE.FORM_SUBMISSION_VIEW)
	}

	const loadDetails = () => {
		mutateActivity(
			{
				activity_id: editItem.activity_id,
				activity_type_id: editItem.activity_type_id,
			},
			{
				onSuccess: async details => {
					const isLead = asset_id === details.activity_lead_asset_id
					const isOwner = asset_flag_super_admin
					dispatch({
						type: sharedConst.ACTVITY_SELECTED,
						payload: {
							...details,
							isLead,
							isOwner,
						},
					})
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	}

	return (
		<Box h='100%' w='98%'>
			<Flex>
				<IconButton
					bg='white'
					borderRadius='md'
					boxShadow='md'
					aria-label='Refresh'
					cursor='pointer'
					size='md'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_DASHBOARD,
						// 	action: 'Widget Data',
						// 	label: 'Go Back',
						// })
						dispatchDrilldown({
							type: drilldownConst.SAVE_FILTER_STATUS,
							payload: {
								[widgetDataHash]: {
									checkbox: selectedCheckbox,
									selectedIndex: null,
									pageLoaded: widgetRecords.length,
								},
							},
						})

						onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_VIEW)
						onToggle()
					}}
					icon={<Icon as={BiArrowBack} w={6} h={6} />}
				/>

				<Spacer />
				<Box mr='4'>
					<InputGroup flex={1} borderRadius='md'>
						<Input
							bg='white'
							placeholder={locale['Smart search']}
							borderRadius='md'
							variant='filled'
							boxShadow='md'
							minW='250px'
							isDisabled={queryFilters.selectedSubApplication?.value === 110}
							onChange={e => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Drilldown Table',
								// 	buttonName: 'Smart Search',
								// })
								setSearch(e.target.value)
							}}
							_focus={{
								border: 'none',
							}}
						/>
						{/* TODO: provide the option to clear the search text */}
						<InputRightElement
							children={
								<Icon
									as={MdSearch}
									color={localStorage.getItem('color')}
									w={6}
									h={6}
								/>
							}
						/>
					</InputGroup>
				</Box>
				<Box mr='4'>
					{widgetHeaderList && (
						<Tooltip label={'Hide Headers'} placement='top'>
							<Box position='relative' display='inline-block'>
								<CustomMenu
									optionList={
										headersArray &&
										Object.values(headersArray)?.map(item => item)
									}
									isCheckBox
									closeOnSelect={false}
									selected={
										selectedHeaderCheckbox &&
										Object.values(selectedHeaderCheckbox)
									}
									onSelect={(item, flag) => {
										let newDropdownData = {}
										newDropdownData = {
											...selectedHeaderCheckbox,
										}
										if (flag) {
											delete newDropdownData[item.label]
										} else {
											newDropdownData
												? (newDropdownData[item.label] = item)
												: (newDropdownData = {
														[item.label]: item,
												  })
										}
										setSelectedHeaderCheckbox(newDropdownData)
									}}
									icon={MdSort}
								/>
							</Box>
						</Tooltip>
					)}
				</Box>
				{!isMobileView ? (
					<Tooltip label={'Download'} placement='top'>
						<Box mr='4'>
							<Popover
								isOpen={isExceedOpen}
								onClose={() => setIsExceedOpen(false)}
							>
								<PopoverTrigger>
									<IconButton
										bg='white'
										isDisabled={!widgetRecords || !widgetRecords.length}
										borderRadius='md'
										boxShadow='md'
										aria-label='Refresh'
										cursor='pointer'
										size='md'
										_focus={{
											bg: 'secondary',
										}}
										_active={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_DASHBOARD,
											// 	action: 'Widget Data',
											// 	label: 'Export',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Drilldown Table',
											// 	buttonName: 'Export',
											// })
											reloadWidgetDrilldown(true)
											setExportFlag(true)
										}}
										spinner={<BeatLoader size={8} color='white' />}
										isLoading={isExportDataLoading}
										icon={<Icon as={BiDownload} w={6} h={6} />}
									/>
								</PopoverTrigger>
								<PopoverContent>
									<PopoverArrow />
									<PopoverCloseButton />
									<PopoverHeader>Records exceeded!</PopoverHeader>
									<PopoverBody>
										Sorry, more than 5000 records are found. Please contact
										administrator
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</Box>
					</Tooltip>
				) : null}
				{dataSet.length > 0 && (
					<Box mr='4'>
						<ReactToPrint content={() => componentRef}>
							<PrintContextConsumer>
								{({ handlePrint }) => (
									<IconButton
										bg='white'
										borderRadius='md'
										boxShadow='md'
										aria-label='Print'
										cursor='pointer</Box>'
										size='md'
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_DASHBOARD,
											// 	action: 'Widget Data',
											// 	label: 'Print',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Drilldown Table',
											// 	buttonName: 'Print',
											// })
											handlePrint(e)
										}}
										icon={<Icon as={MdPrint} w={6} h={6} />}
									/>
								)}
							</PrintContextConsumer>
						</ReactToPrint>
					</Box>
				)}
				<Tooltip label={'Refresh'} placement='top'>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Refresh'
						cursor='pointer'
						size='md'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_DASHBOARD,
							// 	action: 'Widget Data',
							// 	label: 'Refresh',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Drilldown Table',
							// 	buttonName: 'Refresh',
							// })
							setHasMoreData(true)
							reloadWidgetDrilldown(false, 0, true)
							setSelectedCheckbox({})
							resetFiltersDrilldown()
						}}
						icon={<Icon as={MdRefresh} w={6} h={6} />}
					/>
				</Tooltip>
				{queryFilters.selectedSubApplication?.is_card_layout_enabled === 1 && (
					<Tooltip label={'Toggle View'} placement='top'>
						<Box ml='4' display='grid' placeContent='center'>
							<Switch
								defaultChecked={false}
								onChange={e => {
									onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA)
								}}
							/>
						</Box>
					</Tooltip>
				)}
			</Flex>
			<Box
				w='100%'
				h={isMobileView ? 'calc(100vh - 100px)' : 'calc(100vh - 160px)'}
				mt='16px'
				overflow='auto'
				templateColumns='repeat(12, 1fr)'
				gap={5}
			>
				{!isExportDataLoading &&
				!!widgetExportList &&
				widgetExportList.exports.length
					? renderExportData()
					: null}
				{isWidgetHeaderLoading ? (
					<Flex mt='20' justify='center'>
						<PropagateLoader color='#718096' />
					</Flex>
				) : !!widgetHeaderList && widgetHeaderList.length ? (
					// <Scrollbar
					// 	onUpdate={widgetRecords.length >= 100 ? handleScrollUpdate : null}
					// >
					<Flex flexDirection={'column'}>
						<DrillDownTable
							theadings={renderWidgetDrilldownHeader()}
							tdata={renderWidgetDrilldownData()}
							thLength={widgetHeaderList.length + 1}
							onCellClicked={onCellClicked}
						/>
						{!isWidgetDataLoading && widgetRecords.length % 100 === 0 && (
							<Button
								className={`action-panel-add-new-file-button`}
								id={`action-panel-btn-add-new-file`}
								w='10%'
								h='35px'
								marginLeft='45%'
								marginBottom='15px'
								marginTop='15px'
								fontSize='small'
								variant='filled'
								bg={localStorage.getItem('color')}
								color='white'
								borderRadius={6}
								fontWeight='400'
								boxShadow='md'
								textTransform='capitalize'
								onClick={() => {
									//   eventLabelTrack({
									//     categoryId: 0,
									//     actionId: 0,
									//     label: locale['Open View For Adding New File'],
									//   })
									//   dataLayerTagManager('button_click', {
									//     viewName: 'Home',
									//     buttonName: 'Add New File',
									//   })
									handleScrollUpdate()
								}}
							>
								{'Load More'}
							</Button>
						)}
					</Flex>
				) : (
					// </Scrollbar>
					<Flex flexDirection='column' mt='10' align='center' justify='center'>
						<AiFillDatabase size={150} color='#48BB78' />
						<Text color='#48BB78'>No headers configured</Text>
					</Flex>
				)}
			</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent minW='50vw'>
					<ModalHeader fontSize='lg' fontWeight='900'>
						{'Leave Information'}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{leaveData && leaveData.length > 0 ? (
							<Table overflowY='hidden' overflowX='auto' display='block'>
								<Thead position={'relative'} zIndex={1}>
									<Tr>
										<Th>{locale['Leave type']}</Th>
										<Th>{locale['Available Leaves']}</Th>
										<Th>{locale['Start Date Time']}</Th>
										<Th>{locale['End Date Time']}</Th>
										<Th>{locale['Leaves to be taken']}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{leaveData.map((ele, index) => {
										return (
											<Tr key={index}>
												<Td fontSize={12}>{ele.leave_type_name}</Td>
												<Td fontSize={12}>{ele.Leave_available}</Td>
												<Td fontSize={12}>
													{getLeaveTimeDate(ele.leave_start_datetime)}
												</Td>
												<Td fontSize={12}>
													{getLeaveTimeDate(ele.leave_end_datetime)}
												</Td>
												<Td fontSize={12}>{ele.leave_applied}</Td>
											</Tr>
										)
									})}
									<Tr>
										<Td></Td>
										<Td></Td>
										<Td></Td>
										<Td fontSize={12}>{locale['Total Leaves']}</Td>
										<Td fontSize={12}>{toReturnTotalLeaves(leaveData)}</Td>
									</Tr>
								</Tbody>
							</Table>
						) : null}
					</ModalBody>
				</ModalContent>
			</Modal>
			<div style={{ display: 'none' }}>
				<div ref={el => (componentRef = el)}>
					{!!dataSet.length && (
						<Flex width={'full'}>
							<Table overflowY='hidden' overflowX='auto' display='block'>
								<Thead position={'relative'} zIndex={1}>
									<Tr>
										<Th>{'CUID 2'}</Th>
										<Th>{'TITLE'}</Th>
										<Th>{'QR CODE'}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{!!dataSet.length &&
										dataSet.map((d, i) => {
											return (
												<Tr>
													<Td>
														<b>{d.activity_cuid_2}</b>
													</Td>
													<Td>
														<b>{d.activity_title}</b>
													</Td>
													<Td>
														<QRCodeSVG
															renderAs='canvas'
															style={{
																height: '75px',
																width: '95px',
															}}
															level='Q'
															value={`${d.activity_cuid_1}`}
														/>
														<b>{d.activity_cuid_1}</b>
													</Td>
												</Tr>
											)
										})}
								</Tbody>
							</Table>
						</Flex>
					)}
				</div>
			</div>
			{isFormEditView && (
				<SmartFormContextProvider>
					<FormEditPanel
						isOpen={isFormEditView}
						onClose={onCloseFormEdit}
						activity={editItem}
						formData={openFormView}
						refillForm={onFormRefill}
						isFormEnable={activityEnable()}
					/>
				</SmartFormContextProvider>
			)}

			<Drawer
				className={`form-add-onfly-drawer`}
				isOpen={isSendEmail}
				placement='right'
				onClose={onCloseSendEmail}
				size='xl'
				closeOnOverlayClick={false}
			>
				<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
				<DrawerContent className={`form-add-onfly-drawer-content`}>
					<DrawerHeader fontSize='lg' fontWeight='900'>
						{modelType === 'edit'
							? 'Edit Dashboard Info'
							: locale['Data Management']}
					</DrawerHeader>
					<Divider />
					<DrawerCloseButton className={`form-add-onfly-drawer-close-button`} />
					<DrawerBody className={`form-add-onfly-drawer-body`}>
						{WORKSPACE_MODE.FORM_SUBMISSION_VIEW === workspaceMode ? (
							<Box bg='white' borderRadius='md' p={2}>
								<SmartFormContextProvider>
									<SmartFormPanel
										onClose={() => {
											setworkspaceMode(WORKSPACE_MODE.TEAM_COLLABORATION)
											loadDetails(activity)
											setTimeout(() => {
												dispatch({
													type: sharedConst.REFRESH_LISTING,
												})
											}, 1000)
											// doRefresh(0)
										}}
										formData={openFormView}
										activity={activity}
										handleError={() => {
											console.log('error occurred!')
										}}
										refillView={refillView}
										isBulkEdit={isBulkEdit}
									/>
								</SmartFormContextProvider>
							</Box>
						) : modelType === 'edit' ? (
							<>
								<VStack my={5}>
									{!!widgetHeaderList && widgetHeaderList.length
										? widgetHeaderList.map((header, i) => {
												const value1 = getCustomColumnData(header, editItem)

												let getFilteredData = !!headerFieldType
													? headerFieldType.filter(
															item => item.header_id === header.header_id
													  )
													: []
												let fieldInfo =
													getFilteredData.length > 0
														? !!getFilteredData[0]
															? !!getFilteredData[0].field_details
																? getFilteredData[0].field_details[0]
																: null
															: null
														: null
												let formInfo =
													getFilteredData.length > 0
														? !!getFilteredData[0]
															? !!getFilteredData[0].form_details
																? getFilteredData[0].form_details
																: null
															: null
														: null
												let fieldData = {
													...fieldInfo,
													...formInfo,
												}
												return (
													<FormControl
														className={`field-item-edit-form-${toSnakeCase(
															header.header_name
														)}`}
														boxShadow='md'
														borderRadius='md'
														id='field-edit'
														p={2}
														mb={2}
													>
														<FormLabel fontWeight='400' fontSize='md' my={1}>
															{header.header_name}
														</FormLabel>
														{header?.db_field_name.includes('column') &&
														getFilteredData.length &&
														getFilteredData?.[0]?.field_details?.[0]
															?.data_type_id !== 33 ? (
															<FormInputField
																className={`form-input-field-${toSnakeCase(
																	'' +
																		getFilteredData?.[0]?.field_details?.[0]
																			?.field_id
																)}`}
																isRequired={
																	getFilteredData?.[0]?.field_details?.[0]
																		?.field_mandatory_enabled
																}
																field={fieldData}
																isDisabled={false}
																onValidate={onValidate}
																onError={onError}
																isFromEdit={true}
																activity={editItem}
																isFileUpload={params => setFileUplaod(params)}
															/>
														) : (
															<Input
																type='text'
																value={
																	typeof value1 === 'object' ? null : value1
																}
																isDisabled={true}
															/>
														)}
													</FormControl>
												)
										  })
										: null}
								</VStack>
								<Box mt={2} w='full'>
									<HStack justifyContent='flex-end'>
										<Button
											variant='outline'
											size='md'
											borderRadius='sm'
											colorScheme={localStorage.getItem('color')}
											bg='white.400'
											w='140px'
											ml={1}
											onClick={() => {
												onCloseSendEmail()
											}}
										>
											{locale['Cancel']}
										</Button>
										<Button
											variant='solid'
											size='md'
											borderRadius='sm'
											colorScheme={localStorage.getItem('color')}
											bg={localStorage.getItem('color')}
											w='140px'
											ml={1}
											onClick={() => {
												onSubmitEditData()
											}}
										>
											{locale['Submit']}
										</Button>
									</HStack>
								</Box>
							</>
						) : (
							<DataManagement
								participants={participants}
								isOverviewOpen={false}
								isFromWN={false}
								isFormEnable={activityEnable()}
								activity={editItem}
								onFormSelection={(f, isEdit = false, owner_access = true) => {
									const { activity_id, activity_type_id } = editItem
									setopenFormView({
										...f,
										disableEdit: false,
										activity_id,
										activity_type_id,
									})
									setisBulkEdit(false)
									setRefillView(false)
									if (!!isEdit && activityEnable(owner_access)) {
										onOpenFormEditView()
									} else if (!!isEdit && !activityEnable(owner_access)) {
										onOpenNoAccess()
									} else {
										if (activityEnable(owner_access)) {
											setworkspaceMode(WORKSPACE_MODE.FORM_SUBMISSION_VIEW)
										} else {
											onOpenNoAccess()
										}
									}
								}}
								isFromWhatNext={false}
								isFromTable={true}
							/>
						)}
						{/* <Text color="red">{error}</Text> */}
					</DrawerBody>
					<DrawerFooter></DrawerFooter>
				</DrawerContent>
			</Drawer>
			<AlertDialog
				isOpen={isOpenNoAccess}
				leastDestructiveRef={cancelRef}
				onClose={onCloseNoAccess}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{'Form Access Denied'}
						</AlertDialogHeader>

						<AlertDialogBody>
							{'Form access denied. Please contact admin'}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									onCloseNoAccess()
								}}
							>
								{locale['Ok']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Box>
	)
}

export default WidgetData
